.services__container {
  position: relative;

  @media screen and (max-width: 900px) {
    background-color: rgb(242, 242, 247);
  }
  .services__main {
    position: relative;
    z-index: 99;
    background-size: 100%;
    border-radius: 18px;
    padding: 40px;

    @media screen and (max-width: 600px) {
      padding: 0;
      padding-top: 16px;
    }
  }
  .services__header {
    height: 70vh;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .travel__style_heading {
    margin: 0px auto;
    container-type: inline-size;
    width: 100%;
    margin-bottom: 40px;
    @media screen and (max-width: 600px) {
      margin-bottom: 16px;
    }
    .heading {
      font-weight: 700;
      font-size: 42px;
      color: #ffffff;
      letter-spacing: 0.28px;
      margin: 0;

      @media screen and (max-width: 600px) {
        color: #020405;
        font-size: 24px;
        font-weight: 600;
      }

      @media screen and (max-width: 900px) {
        color: #020405;
      }
    }

    .sub-heading {
      font-weight: 500;
      font-size: 24px;
      line-height: 52px;
      color: #ffffff;
      letter-spacing: 0.44px;
      margin: 0;

      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 24px;
        color: #020405;
      }

      @media screen and (max-width: 900px) {
        color: #020405;
      }
    }
  }

  .travel__style {
    // padding-top: 2rem;

    .heading {
      font-weight: 500;
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 78px;
      letter-spacing: 5px;
      padding: 0 24px;
      color: #ffffff;

      @media screen and (max-width: 600px) {
        font-size: 27px;
        margin-bottom: 20px;
        font-weight: 700;
      }
    }

    .booking__box {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
      }

      .arBottom {
        .MuiInputBase-root {
          &:after {
            right: auto !important;
            left: 0 !important;
          }
        }
      }

      .bottom {
        position: relative;
        color: white;
        padding: 6px;
        box-sizing: border-box;
        background-color: #ffffff !important;
        z-index: 999;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04);
        border-radius: 12px;

        @media screen and (max-width: 900px) {
          border-radius: 0px 0px 12px 12px;
          padding: 20px !important;
        }

        form {
          .MuiInputBase-root,
          .MuiSelect-select,
          input {
            border: none !important;

            &:hover {
              background-color: #ffffff !important;
            }

            &:focus {
              background-color: #ffffff !important;
            }
          }

          .MuiInputBase-root {
            &:after {
              display: block !important;
              position: absolute !important;
              content: "" !important;
              top: 12px !important;
              bottom: 12px !important;
              width: 1px !important;
              right: 0;
              left: auto;
              transform: scaleX(1) !important;
              background-color: #ecebf2 !important;
              border-bottom: none !important;

              @media screen and (max-width: 900px) {
                width: 100% !important;
                left: 0;
                right: 0;
                bottom: -6px !important;
                top: auto !important;
                height: 1px !important;
              }
            }

            .MuiButtonBase-root {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
