.sidebar__container {
  // height: 550px;
  background-color: rgb(242, 242, 247);

  .header {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 12px;
    justify-content: space-between;
  }

  .side__links {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    padding: 16px;
    // border-bottom: 1px solid gray;

    // .active {
    //     color: white !important;
    // }
    .dropdown {
      display: flex;
    }

    a, .link_side {
      cursor: pointer;
      color: #020405;
      // color: white;
      padding: 16px 0;
      text-decoration: none;
      font-size: 17px;
      font-weight: 500;
      border-bottom: 1px solid #e4e4e4;
    }

    // .active{
    //     padding: 10px;
    //     background-color: gray;
    //     border-radius: 10px;
    // }
  }

  // .MuiBox-root{
  //     height: fit-content !important;
  //    }
}
