.navbar__container {
  top: 0;
  z-index: 1024;
  backdrop-filter: blur(7.5px);
  padding: 24px 0;
  // border-bottom: 1px solid #DDDCE5;
  .navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      box-shadow: none !important;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .logo__text {
    font-style: normal;
    font-weight: 100;
    font-size: 25px;
    line-height: 30px;
    margin-left: 8px;
    text-align: center;
    letter-spacing: 5px;
    color: #ffcc00;
  }

  .routes {
    display: flex;
    align-items: center;
    gap: 28px;

    a {
      color: #0a0a0e;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-decoration: none;
      text-transform: capitalize;
      @media screen and (min-width: 1000px) and (max-width: 1100px) {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .routes {
      display: none;
    }

    .hide__login {
      display: none;
    }
  }

  .burger__icons {
    display: none;
    position: absolute;
    right: 24px;
    top: 22px;
    font-size: 32px;
    color: black;

    @media screen and (max-width: 600px) {
      right: 16px;
    }
    @media screen and (max-width: 1000px) {
      display: block;
    }
  }
  .ar_burger {
    right: auto !important;
    left: 10px !important;
  }
}

.links {
  cursor: pointer;
  color: #0a0a0e;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 0;
  text-decoration: none;
  text-transform: capitalize;
  @media screen and (min-width: 1000px) and (max-width: 1100px) {
    font-size: 13px;
  }
}

.links:hover {
  color: #520a76;
  transition: all 0.3s ease;
}

.MuiListItem-root:focus-visible {
  outline: none !important;
}
