@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=arabic:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

// @font-face {
//   font-family: "arabic";
//   src: url("./assets/font/YeloEnglish/SharpSans-Medium.ttf");
// }
// @font-face {
//   font-family: "sharpSansBold";
//   src: url("./assets/font/YeloEnglish/SharpSans-Bold.ttf");
// }

/* Light */
@font-face {
  font-family: "arabic";
  src: url("./assets/font/YeloArabic/29LTBukra-Light.otf") format("opentype");
  font-weight: 300; /* Light weight */
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: "arabic";
  src: url("./assets/font/YeloArabic/29LTBukra-Regular.otf") format("opentype");
  font-weight: 400; /* Normal weight */
  font-style: normal;
}

/* Medium */
@font-face {
  font-family: "arabic";
  src: url("./assets/font/YeloArabic/29LTBukra-Medium.otf") format("opentype");
  font-weight: 600; /* Normal weight */
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "arabic";
  src: url("./assets/font/YeloArabic/29LTBukra-Bold.otf") format("opentype");
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

/*Extra  Bold */
@font-face {
  font-family: "arabic";
  src: url("./assets/font/YeloArabic/29LTBukra-ExtraBold.otf")
    format("opentype");
  font-weight: 900; /* Bold weight */
  font-style: normal;
}

* {
  letter-spacing: 0.5px;
}

html {
  scroll-behavior: smooth;
  font-family: "arabic", sans-serif !important;
}

body {
  color: #0a0a0e;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
}

p {
  color: #0a0a0e;
}

.profile-img {
  position: relative;
}

.profile-img img {
  /* padding: 10px; */
  border-radius: 50%;
  object-fit: cover;
  /* border: 1px dashed #cdcdcd; */
}

.profile-img p {
  margin-top: 0;
  font-size: 14px;
}

.MuiModal-root h3 {
  margin-top: 0;
}

.gm-ui-hover-effect {
  display: none !important;
}

.app-modal-body::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.app-modal-body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
  box-shadow: inset 0 0 5px #fff;
}

/* Handle */
.app-modal-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: gray;
  border: 1px solid white;
}

.app-modal-body::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

.sentry-error-embed-wrapper {
  z-index: 9999 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border-radius: 12px !important;
  box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

.privacy-section {
  margin-bottom: 50px;
}

.privacy-block {
  padding-top: 75px;
}

.privacy-heading {
  font-size: 3.5rem;
  color: #0a0a0e;
  line-height: 1;
}

.privacy-sub-heading {
  color: #0a0a0e;
  font-size: 1.25rem;
  margin: 0;
}

.privacy-mini-heading {
  font-size: 1.25rem;
  line-height: 1;
  margin-bottom: 0;
}

.privacy-block p,
.privacy-block li {
  margin-top: 0;
  color: #67758a;
}

.privacy-block li:last-child() {
  margin-bottom: 0;
}

.privacy-block a {
  color: #0a0a0e;
  word-break: break-all;
  text-decoration: none;
  transition: all 0.3s ease;
}

.privacy-block a:hover {
  color: #f5d312;
}

.privacy-menu {
  padding: 15px;
  top: 10%;
  position: sticky;
  box-shadow: 1px 1px 30px -15px #cdcdcd;
}

.privacy-menu ul {
  padding: 0;
}

.privacy-menu ul li {
  list-style-type: none;
}

.privacy-menu ul li a {
  padding: 15px;
  color: #0a0a0e;
  font-weight: 500;
  display: block;
  text-decoration: none;
  transition: all 0.3s ease;
}

.privacy-menu ul li a:hover {
  color: #ffcd00;
}

.privacy-block ol li {
  margin-bottom: 10px;
}

.privacy-block ol li:nth-last-child(1) {
  margin-bottom: 0;
}

.imprint-block {
  padding: 10px 0 !important;
}

.main-section {
  padding: 35px 0;
  padding-top: 100px;
}

/* .header-padding {
	padding-top: 120px;
} */

/* .MuiIconButton-root.MuiIconButton-edgeEnd {
	display: none;
} */

.section {
  padding: 50px 0;
}

.section-main-heading h2 {
  font-size: 50px;
  line-height: 60px;
  margin-top: 0;
}

/* Stripe Styles */
.StripeElement {
  padding: 12px 16px;
  border: 1px solid rgb(205, 205, 205);
}

/* .StripeElement--empty {} */

.StripeElement--invalid {
  border-color: red;
}

.StripeElement--focus {
  border-color: #0a0a0e;
}

.StripeElement--complete {
  border-color: green;
}

/* .StripeElement--webkit-autofill {} */

.checkout-card {
  padding: 25px;
  position: relative;
  border-radius: 8px;
  margin-bottom: 25px;
  background-color: #f8f8f8;
}
.paymentForm__container {
  padding: 25px;
  background-color: #f8f8f8;
  // border: 1px solid  #520A76;

  @media screen and (max-width: 580px) {
    padding: 14px;
  }
}

.checkout-card-heading {
  margin: 0;
  font-size: 20px;
}

.text-with-icon {
  display: flex;
  align-items: center;
}

.text-with-icon svg {
  fill: #0a0a0e;
}

.text-with-icon img {
  margin-right: 10px;
}

.text-with-icon p {
  margin: 6px 0;
  margin-right: 6px;
  color: #828282;
}

.partner-logo {
  text-align: center;
}

.MuiTimelineDot-root.MuiTimelineDot-filled.MuiTimelineDot-filledPrimary {
  border-radius: 0 !important;
}

@media only screen and (max-width: 1400px) {
  .section {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 992px) {
  .privacy-heading {
    font-size: 2rem;
  }
}

.cursor {
  cursor: pointer;
}

.choseLocation {
  border-radius: 40px;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  max-width: 200px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 62%);
  color: white;
  background: #520a76;
  padding: 4px 7px;
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  border: 0;
}
.choseLocation:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #818181;
}

.changeArabic {
  font-family: "arabic" !important;
}

.rotate {
  transform: rotate(180deg);
}

.active {
  color: #520a76 !important;
}

a {
  text-decoration: none !important;
}

.remaining {
  height: 100%;
}

.paymentform__header {
  background: #520a76;
  border: 1px solid #520a76;
  border-radius: 7px 7px 0px 0px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay_amount {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  margin-bottom: 0.7rem;
  // background-color: #f8f8f8;
  // border-bottom: 1px solid #520A76;
  border: 1px solid #520a76;
  // border-right: 2px solid  #520A76;
  h2 {
    // font:700 20px/26px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;

    color: #520a76;
    margin: 0;
    padding: 0;
  }
  p {
    // font:700 20px/26px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;

    color: #520a76;

    margin: 0;
    padding: 0;
    margin-top: -10px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #520a76;
  }
}

.failedImg {
  background-color: #f8f8f8;
}

.mobile_icon {
  @media screen and (max-width: 980px) {
    transform: rotate(90deg);
  }
}

.card_option1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  margin: 0px 40px;
  border: 1px solid rgba(128, 128, 128, 0.144);
  border-radius: 3px;
  cursor: pointer;
  p {
    color: #520a76;
    font-weight: 600;
    font-size: 16px;
  }
}

.activeBorder {
  border: 1px solid #520a76;
}

.thanks-section {
  padding-top: 80px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 85px);
}

.thanks-section img {
  max-width: 450px;
}

.thanks-section h1 {
  margin-top: 32px;
  margin-bottom: 16px;
}

.thanks-section p {
  margin-top: 0;
}

.payment-card {
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ffffff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 6px 20px -4px;
  transition: all 0.3s ease;
}

.payment-card.default {
  border: 1px solid #f0a857;
}

.payment-card:hover {
  background-color: #f8f8f8;
}

.payment-cards-heading {
  font-size: 16px;
  margin: 10px 0;
}

.ballance-card p {
  margin: 10px 0;
}

.payment-card-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-card-banner p {
  font-size: 18px;
  margin-right: 5px;
  word-break: break-all;
}

.payment-card-type-img img {
  width: 50px;
}

.payment-card-type-img svg {
  display: block;
}

#TFCallButtonFloat {
  bottom: 24px !important;
  right: 24px !important;
  z-index: 999 !important;

  @media (max-width: 768px) {
    display: none;
    bottom: 16px !important;
    right: 16px !important;
  }
}

.TFCallNowButton {
  bottom: 60px !important;
  right: 60px !important;
  z-index: 999 !important;
  @media (max-width: 768px) {
    display: none;
    bottom: 40px !important;
    right: 40px !important;
  }
}

.SnackbarItem-message {
  z-index: 9999 !important;
}
[aria-label][data-balloon-pos][data-balloon-visible]:before,
[aria-label][data-balloon-pos][data-balloon-visible]:after {
  opacity: 0 !important;
}
