.city-swiper .swiper-slide {
  width: auto;
  height: auto;
}

.swiper-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.city-swiper {
  padding: 8px 4px;
  // margin: -8px -4px;
}

.custom-swiper-button-prev {
  position: absolute;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7fc;
  border-radius: 8px;
  transform: translateY(-50%);
  top: 24px;
  right: 78px;
  left: auto;
  cursor: pointer;

  &:after {
    font-size: 16px;
    color: #520a76;
  }

  @media screen and (max-width: 600px) {
    right: 70px;
    display: none;
  }
}

.custom-swiper-button-prev-ar {
  right: auto !important;
  left: 78px !important;
  transform-origin: top;
  transform: rotate(180deg) !important;

  @media screen and (max-width: 600px) {
    right: 70px;
  }
}

.custom-swiper-button-next-ar {
  right: auto !important;
  left: 24px !important;
  transform-origin: top;
  transform: rotate(180deg) !important;
}

.custom-swiper-button-next {
  right: 24px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7fc;
  border-radius: 8px;
  top: 24px;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;

  @media screen and (max-width: 600px) {
    right: 16px;
    display: none;
  }

  &:after {
    font-size: 16px;
    color: #520a76;
  }
}

.full_container_for_slider {
  .swiper:not(.swiper-rtl) .swiper-wrapper > div:first-of-type
  // , .swiper:not([dir="rtl"]) .swiper-wrapper > div:first-of-type
  {
    padding-left: calc((100vw - 1440px) / 2) !important;
    @media screen and (max-width: 1480px) {
      padding-left: 24px !important;
    }
    @media screen and (max-width: 600px) {
      padding-left: 16px !important;
    }
  }

  .swiper-rtl .swiper-wrapper > div:first-of-type,
  [dir="rtl"] .swiper .swiper-wrapper > div:first-of-type {
    padding-right: calc((100vw - 1440px) / 2) !important;
    @media screen and (max-width: 1480px) {
      padding-right: 24px !important;
    }
    @media screen and (max-width: 600px) {
      padding-right: 16px !important;
    }
  }

  .swiper:not(.swiper-rtl) .swiper-wrapper > div:last-of-type {
    padding-right: calc((100vw - 1440px) / 2) !important;
    @media screen and (max-width: 1480px) {
      padding-right: 24px !important;
    }
    @media screen and (max-width: 600px) {
      padding-right: 16px !important;
    }
  }

  .swiper-rtl .swiper-wrapper > div:last-of-type,
  [dir="rtl"] .swiper .swiper-wrapper > div:last-of-type {
    padding-left: calc((100vw - 1440px) / 2) !important;
    @media screen and (max-width: 1480px) {
      padding-left: 24px !important;
    }
    @media screen and (max-width: 600px) {
      padding-left: 16px !important;
    }
  }
}
