.class-badge {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 8px 2px 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 580px) {
  .class-badge {
    font-size: 12px;
    font-weight: 400;
  }
}
