.login-container {
  width: 100%;
  position: relative;
  z-index: 1;
  background: rgb(242, 242, 247);
  min-height: calc(100vh - 79px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container-search {
  width: 100%;
  z-index: 1;
  position: relative;
  background: rgb(242, 242, 247);
}

.wrap-login {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  padding: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
}

.login-form-inner {
  padding-top: 24px;
}

.login-form-title {
  font-size: 24px;
  color: #0a0a0e;
  line-height: 1.2;
  display: block;
  text-align: center;
  font-weight: 500;
}

.login-form-slogan {
  color: #0a0a0e;
  font-size: 14px;
  display: block;
  margin-top: 10px;
  text-align: center;
  opacity: 0.6;
}

.forget-text {
  margin-top: 10px;
}

.forget-text a {
  text-decoration: none;
  display: block;
  color: #0a0a0e;
  font-size: 14px;
  margin-top: 5px;
  text-align: right;
  transition: all 0.3s ease;
}

.forget-text a:hover {
  color: #f5d312;
}
