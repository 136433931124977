.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #04AA6D;
    border-bottom: 5px solid #04AA6D;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#loader{
    width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background: #2e2b2b;
        z-index: 100000;
        flex-direction: column;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
}

#loader h3{
    color:white
}
#payment_form_div{
    display: none;
}