.pickUp__field {
  position: relative;
  .swap__image {
    position: absolute;
    top: 5px;
    right: -32px;
    z-index: 9000;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      top: 34px;
      transform: rotate(270deg);
      left: 0;
      right: 0;
    }
  }
  .arSwap {
    right: auto !important;
    left: -24px;
    @media screen and (max-width: 900px) {
      margin: 0 auto;
      left: 0;
      right: 0 !important;
    }
  }
}

.pinMap {
  position: absolute;
  top: 84px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .responsiveMap {
    top: 160px !important;
  }
}
